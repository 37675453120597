import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import ProtectedRoutes from './components/ProtectedRoute';
import Login from './routes/login';
import Register from './routes/register';
import './index.css';
import App from './App';
import Main from './components/Main';
import Map from './routes/map';
import Profile from './routes/profile';
import Leaderboard from './routes/leaderboard';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
      <Router>
        <Routes>
          <Route path="/" element={<App />}>
            <Route index element={<Main />} />
            <Route path="register" element={<Register />} />
            <Route path="login" element={<Login />} />
            {/* <Route element={<ProtectedRoutes />}> */}
              <Route path="map" element={<Map />} />
              <Route path="map/:hexagonId" element={<Map />} />
              <Route path="profile" element={<Profile />} />
              <Route path="profile/:sectionName" element={<Profile />} />
              <Route path="profile/user/:externalUser" element={<Profile />} />
              <Route path="leaderboard" element={<Leaderboard />} />
            {/* </Route> */}
          </Route>
        </Routes>
      </Router>
  // </React.StrictMode>
);
