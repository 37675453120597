import React, { useState } from 'react';
import { useNavigate, Link } from "react-router-dom";
import { Container, TextField, Button, Typography, Alert } from '@mui/material';
import usersService from "../services/users.service";

import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';


const Register = () => {
    const [formData, setFormData] = useState({
        username: '',
        password: '',
        email: '',
        repeatPassword: ''
    });
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();

    const handlePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    // Function to handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Clear previous messages
        setError('');
        setSuccess('');

        // Frontend validation
        if (!formData.username || !formData.email || !formData.password) {
            setError('All fields are required');
            return;
        }

        if (formData.password.length < 8) {
            setError('Password must be at least 8 characters long');
            return;
        }

        const emailRegex = /\S+@\S+\.\S+/;
        if (!emailRegex.test(formData.email)) {
            setError('Invalid email format');
            return;
        }

        // Check if passwords match
        if (formData.password !== formData.repeatPassword) {
            setError('Passwords do not match');
            return;
        }

        try {
            const oRegisterResult = await usersService.register(
                formData.username,
                formData.password,
                formData.email
            );

            // Handle different responses from backend here
            if (oRegisterResult.status === 201) {
                setSuccess('Registration successful! Redirecting to login...');
                setTimeout(() => navigate("/login"), 3000); // Redirect after 3 seconds
            } else {
                setError('Failed to register. Please try again.'); // Generic error message
            }
        } catch (err) {
            // Handle errors from the backend
            setError(err.response?.data?.message || 'Registration failed. Please try again.');
        }
    };

    // Function to update state when the user types in the form fields
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setError(''); // Clear error message when user starts typing again
    };

    return (
        <Container component="main" maxWidth="xs">
            <Typography component="h1" variant="h5" sx={{ mt: 6 }}>
                Sign up
            </Typography>
            {error && (
                <Alert severity="error" sx={{ mt: 2 }}>
                    {error}
                </Alert>
            )}
            {success && <Alert severity="success" sx={{ mt: 2 }}>{success}</Alert>}
            <form onSubmit={handleSubmit}>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="username"
                    label="Username"
                    name="username"
                    autoFocus
                    onChange={handleChange}
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    onChange={handleChange}
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="password"
                    label="Password"
                    type={showPassword ? "text" : "password"}
                    name="password"
                    onChange={handleChange}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handlePasswordVisibility}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="repeatPassword"
                    label="Repeat Password"
                    type={showPassword ? "text" : "password"}
                    name="repeatPassword"
                    onChange={handleChange}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handlePasswordVisibility}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />

                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                >
                    Sign Up
                </Button>
            </form>
            <Typography variant="body2" sx={{ mt: 2, textAlign: 'center' }}>
                Already have an account?
                <Link to="/login" style={{
                    textDecoration: 'none',
                    color: '#bf5700',
                    marginLeft: '5px'
                }}>
                    Login
                </Link>
            </Typography>
        </Container>
    );
};

export default Register;

