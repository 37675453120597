import React, { useState, useEffect } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Select,
    MenuItem,
    Box,
    CircularProgress,
    Alert,
} from '@mui/material';

import { Link } from 'react-router-dom';

import leaderboardService from '../services/leaderboard.service';

const Leaderboard = () => {
    const [leaderboardType, setLeaderboardType] = useState('user');
    const [state, setState] = useState('overall');
    const [influenceData, setInfluenceData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        setLoading(true);
        leaderboardService.getInfluenceLeaderboard(leaderboardType, state)
            .then(data => {
                setInfluenceData(data.data);
                setLoading(false);
            })
            .catch(error => {
                console.error(error);
                setError('Failed to fetch leaderboard data');
                setLoading(false);
            });
    }, [leaderboardType, state]);

    const renderInfluenceLeaderboardTable = () => (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>User</TableCell>
                        {leaderboardType === 'user' ? (
                            <TableCell>Faction</TableCell>
                        ) : (null)}
                        <TableCell align="right">Influence</TableCell>
                        <TableCell>State</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {influenceData.map((item, index) => {
                        const uniqueKey = item.id || `item-${index}`; // Fallback to index if id is not available
                        const username = item.username ? item.username : 'N/A';

                        return (
                            <TableRow key={uniqueKey}>
                                <TableCell>
                                    {leaderboardType === 'user' ? (
                                        <Link to={`/profile/user/${item.userId}`} style={{ color: '#bf5700' }}>
                                            {item.username}
                                        </Link>
                                    ) : (
                                        item.faction
                                    )}
                                </TableCell>
                                {leaderboardType === 'user' ? (
                                    <TableCell>{item.orgName}</TableCell>
                                ) : (null)}
                                <TableCell align='right'>{item.influence}</TableCell>
                                <TableCell>{item.subregion}</TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );

    const handleLeaderboardChange = (event) => {
        setLeaderboardType(event.target.value);
    };

    const handleStateChange = (event) => {
        setState(event.target.value);
    };

    return (
        <Box sx={{ width: '100%', maxWidth: 960, my: 4, mx: 'auto' }}>
            {/* Leaderboard Type Selection */}
            <Select
                value={leaderboardType}
                onChange={handleLeaderboardChange}
                displayEmpty
                inputProps={{ 'aria-label': 'Leaderboard Type' }}
                sx={{ mb: 2 }}
            >
                <MenuItem value="user">Most Influential User</MenuItem>
                <MenuItem value="faction">Most Influential Faction</MenuItem>
            </Select>

            {/* State Selection */}
            <Select
                value={state}
                onChange={handleStateChange}
                displayEmpty
                inputProps={{ 'aria-label': 'State' }}
                sx={{ mb: 2 }}
            >
                <MenuItem value="overall">Overall</MenuItem>
                <MenuItem value="Alabama">Alabama</MenuItem>
                <MenuItem value="Alaska">Alaska</MenuItem>
                <MenuItem value="Arizona">Arizona</MenuItem>
                <MenuItem value="Arkansas">Arkansas</MenuItem>
                <MenuItem value="California">California</MenuItem>
                <MenuItem value="Colorado">Colorado</MenuItem>
                <MenuItem value="Connecticut">Connecticut</MenuItem>
                <MenuItem value="Delaware">Delaware</MenuItem>
                <MenuItem value="Florida">Florida</MenuItem>
                <MenuItem value="Georgia">Georgia</MenuItem>
                <MenuItem value="Hawaii">Hawaii</MenuItem>
                <MenuItem value="Idaho">Idaho</MenuItem>
                <MenuItem value="Illinois">Illinois</MenuItem>
                <MenuItem value="Indiana">Indiana</MenuItem>
                <MenuItem value="Iowa">Iowa</MenuItem>
                <MenuItem value="Kansas">Kansas</MenuItem>
                <MenuItem value="Kentucky">Kentucky</MenuItem>
                <MenuItem value="Louisiana">Louisiana</MenuItem>
                <MenuItem value="Maine">Maine</MenuItem>
                <MenuItem value="Maryland">Maryland</MenuItem>
                <MenuItem value="Massachusetts">Massachusetts</MenuItem>
                <MenuItem value="Michigan">Michigan</MenuItem>
                <MenuItem value="Minnesota">Minnesota</MenuItem>
                <MenuItem value="Mississippi">Mississippi</MenuItem>
                <MenuItem value="Missouri">Missouri</MenuItem>
                <MenuItem value="Montana">Montana</MenuItem>
                <MenuItem value="Nebraska">Nebraska</MenuItem>
                <MenuItem value="Nevada">Nevada</MenuItem>
                <MenuItem value="New Hampshire">New Hampshire</MenuItem>
                <MenuItem value="New Jersey">New Jersey</MenuItem>
                <MenuItem value="New Mexico">New Mexico</MenuItem>
                <MenuItem value="New York">New York</MenuItem>
                <MenuItem value="North Carolina">North Carolina</MenuItem>
                <MenuItem value="North Dakota">North Dakota</MenuItem>
                <MenuItem value="Ohio">Ohio</MenuItem>
                <MenuItem value="Oklahoma">Oklahoma</MenuItem>
                <MenuItem value="Oregon">Oregon</MenuItem>
                <MenuItem value="Pennsylvania">Pennsylvania</MenuItem>
                <MenuItem value="Rhode Island">Rhode Island</MenuItem>
                <MenuItem value="South Carolina">South Carolina</MenuItem>
                <MenuItem value="South Dakota">South Dakota</MenuItem>
                <MenuItem value="Tennessee">Tennessee</MenuItem>
                <MenuItem value="Texas">Texas</MenuItem>
                <MenuItem value="Utah">Utah</MenuItem>
                <MenuItem value="Vermont">Vermont</MenuItem>
                <MenuItem value="Virginia">Virginia</MenuItem>
                <MenuItem value="Washington">Washington</MenuItem>
                <MenuItem value="West Virginia">West Virginia</MenuItem>
                <MenuItem value="Wisconsin">Wisconsin</MenuItem>
                <MenuItem value="Wyoming">Wyoming</MenuItem>
            </Select>

            {/* Loading and Error Handling */}
            {loading && <CircularProgress />}
            {error && <Alert severity="error">{error}</Alert>}

            {/* Render Leaderboard Table */}
            {!loading && !error && renderInfluenceLeaderboardTable()}
        </Box>
    );
};

export default Leaderboard;
