// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mapboxgl-popup-content {
    width: 250px;
    background-color: #2c3e50;
    color: #ecf0f1;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
  }
  
  .mapboxgl-popup-content h3 {
    margin: 0;
    font-size: 18px;
  }
  
  .mapboxgl-popup-content p {
    margin: 10px 0;
  }
  
  .mapboxgl-popup-content button {
    background: #3498db;
    color: #ecf0f1;
    border: none;
    border-radius: 3px;
    padding: 8px 12px;
    cursor: pointer;
  }
  
  .mapboxgl-popup-content button:hover {
    background: #2980b9;
  }
  `, "",{"version":3,"sources":["webpack://./src/styling/buyHexPopup.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,yBAAyB;IACzB,cAAc;IACd,aAAa;IACb,kBAAkB;IAClB,oCAAoC;EACtC;;EAEA;IACE,SAAS;IACT,eAAe;EACjB;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,mBAAmB;IACnB,cAAc;IACd,YAAY;IACZ,kBAAkB;IAClB,iBAAiB;IACjB,eAAe;EACjB;;EAEA;IACE,mBAAmB;EACrB","sourcesContent":[".mapboxgl-popup-content {\n    width: 250px;\n    background-color: #2c3e50;\n    color: #ecf0f1;\n    padding: 15px;\n    border-radius: 8px;\n    box-shadow: 0 0 10px rgba(0,0,0,0.2);\n  }\n  \n  .mapboxgl-popup-content h3 {\n    margin: 0;\n    font-size: 18px;\n  }\n  \n  .mapboxgl-popup-content p {\n    margin: 10px 0;\n  }\n  \n  .mapboxgl-popup-content button {\n    background: #3498db;\n    color: #ecf0f1;\n    border: none;\n    border-radius: 3px;\n    padding: 8px 12px;\n    cursor: pointer;\n  }\n  \n  .mapboxgl-popup-content button:hover {\n    background: #2980b9;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
