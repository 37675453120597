import React, { useState, useEffect } from 'react';
import { useOutletContext } from "react-router-dom";
import { Card, CardContent, CardHeader, Grid, Typography, Button } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import DoneIcon from '@mui/icons-material/Done'; // Import for claimed status

import achievementsService from '../../services/achievements.service';

const Achievements = ( externalUser ) => {
  const [user, setUser] = useOutletContext();
  const [achievements, setAchievements] = useState([]);

  useEffect(() => {
    const targetUserId = externalUser.userId || user.userId;
    achievementsService.getAchievements(targetUserId).then(
      (response) => {
        setAchievements(response.data);
      },
      (error) => {
        console.log(error);
      }
    );
  }, [externalUser, user]);

  // Function to format the date and time
  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString();
  };

  // Function to return an icon based on status
  const getStatusIcon = (status) => {
    switch(status) {
      case 'In Progress':
        return <AutorenewIcon />;
      case 'Completed':
        return <CheckCircleOutlineIcon />;
      case 'Claimed':
        return <CheckCircleOutlineIcon color="primary"/>;
      default:
        return null;
    }
  };

  // Function to claim an achievement
  const claimAchievement = (achievementId) => {
    achievementsService.claimAchievement(achievementId).then(
      (response) => {
        // Update achievements list and user stats here
        setAchievements(response.data.achievements);
        setUser(response.data.user);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  return (
    <Grid container spacing={3}>
      {achievements.length > 0 ? (
        achievements.map((achievement) => (
          <Grid item xs={12} sm={6} md={4} key={achievement.id}>
            <Card>
              <CardHeader
                title={achievement.title}
                subheader={formatDate(achievement.createdAt)}
                avatar={getStatusIcon(achievement.status)}
              />
              <CardContent>
                <Typography variant="body1" gutterBottom>
                  {achievement.description}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Reward: {achievement.points} Grid Gold
                </Typography>
                {achievement.status === 'Claimed' && (
                  <Typography variant="body2" color="textSecondary">
                    Claimed: {formatDate(achievement.earnedAt)}
                  </Typography>
                )}
                {achievement.status === 'Completed' && ( !externalUser.userId || externalUser.userId === user.userId) && (
                  <Button
                    color="primary"
                    onClick={() => claimAchievement(achievement.id)}
                  >
                    Claim
                  </Button>
                )}
              </CardContent>
            </Card>
          </Grid>
        ))
      ) : (
        <Grid item xs={12}>
          <Typography variant="h6" align="center">
            You have not earned any achievements yet. Claim your first land to start your journey...
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default Achievements;
