const getConfig = () => {
	let sDOMAIN;
	let sAPI_URL;
    
	if (window.location.href.includes("http://localhost:3000/")) {
		sAPI_URL = "http://localhost:8089/";
		sDOMAIN = "localhost:3000";
	} else {
		sAPI_URL = "https://api.terradominia.com/";
		sDOMAIN = "terradominia.com";
	}

	return {
		API_URL: sAPI_URL + "api/",
		DOMAIN: sDOMAIN,
	};
};

const config = getConfig();
export default config;
