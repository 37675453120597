import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, Button, Tooltip } from '@mui/material';

const HowToModal = ({ user, open, setOpen }) => {
  const [page, setPage] = useState(1);

  useEffect(() => {
    const showModal = localStorage.getItem('showHowToModal');
    if (showModal !== 'false') {
      setOpen(true);
    }
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      setPage(1); // Reset to the first page when modal opens
    }
  }, [open]);

  const handleDontShowAgain = () => {
    localStorage.setItem('showHowToModal', 'false');
    setOpen(false);
  };

  const handleNextPage = () => {
    setPage(page + 1);
  };

  const handlePreviousPage = () => {
    setPage(page - 1);
  };

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: '90%', md: 'auto' },
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 2,
    maxHeight: '90vh',
    overflowY: 'auto'
  };

  const imageStyle = {
    width: '100px',
    height: '100px',
    marginBottom: '20px',
    borderRadius: '50%',
    maxWidth: '100%'
  };

  const hexagonImageStyle = {
    height: '100px',
    border: '1px solid black',
    borderRadius: '5px',
    maxWidth: '100%'
  };

  const factions = [
    { name: 'Crimson Coalition', logo: 'CrimsonCoalitionLogo.png' },
    { name: 'Sapphire Sovereigns', logo: 'SapphireSovereignsLogo.png' },
    { name: 'Sunset Sentinels', logo: 'SunsetSentinelsLogo.png' },
    { name: 'Lavender Legion', logo: 'LavenderLegionLogo.png' },
    { name: 'Willow Wardens', logo: 'WillowWardensLogo.png' },
  ];

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={modalStyle}>
        {page === 1 && (
          <>
            <Typography id="modal-title" variant="h6" component="h2">
              Welcome to Terra Dominia!
            </Typography>
            <Typography variant="body1" component="p" textAlign="center">
              You have been assigned to the {user.orgName} and were awarded with <span style={{ color: '#bf5700' }}>20 Grid Gold</span> to start your journey. Your goal
              is to expand your influence in this world by claiming land.
            </Typography>
            <img
              src={`/${user.orgLogo}`}
              alt={`${user.orgName} Logo`}
              style={imageStyle}
            />
            <Typography variant="body1" component="p" textAlign="center">
              You will receive an additional <span style={{ color: '#bf5700' }}>10 Grid Gold</span> every hour if you come back to the game.
              Completing achievements will give you additional rewards. 
              <br/><br/>By clicking the How-To option in the menu you can always open this popup again.
              Keep on reading to learn about the basics of this game.
            </Typography>
            <Button variant="contained" onClick={handleNextPage}>Next</Button>
          </>
        )}

        {page === 2 && (
          <>
            <Typography id="modal-title" variant="h6" component="h2">
              Factions
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: '10px' }}>
              {factions.map((faction) => (
                <Tooltip key={faction.name} title={faction.name} placement="top">
                  <img
                    src={`${process.env.PUBLIC_URL}/${faction.logo}`}
                    alt={`${faction.name} Logo`}
                    style={imageStyle}
                  />
                </Tooltip>
              ))}
            </Box>
            <Typography variant="body1" component="p" textAlign="center">
              There are 5 factions in this world. Each faction fights for influence and control over land and resources.
              The current active theater of operations is the <span style={{ color: '#bf5700' }}>United States</span>.
            </Typography>
            <Typography id="modal-title" variant="h6" component="h2">
              Land
            </Typography>
            <Typography variant="body1" component="p" textAlign="center">
              Clicking a state or zooming in will show you the grid. Here you can see the land that is available to you.
              Use your Grid Gold to claim land and expand your influence. Claiming hexagons in patterns will unlock
              new options for your land. A Cluster will be formed when you claim three connection hexagons. A Hub will be formed when you 
              claim seven hexagons in a star pattern. The <span style={{ color: '#bf5700' }}>central hexagon</span> of a hub determines it's type.
            </Typography>
            <img
              src={`${process.env.PUBLIC_URL}/HexagonPatterns.png`}
              alt={"Hexagon Patterns"}
              style={hexagonImageStyle}
            />
            <Button variant="contained" onClick={handlePreviousPage}>Back</Button>
            <Button variant="outlined" onClick={handleDontShowAgain}>Close and Don't Show Again</Button>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default HowToModal;
