import axios from "./axiosConfig";
import config from "./config";

const API_URL = config.API_URL + "map";

const hexagons = async (sw, ne) => {
	return await axios.post(API_URL + "/hexagons", { sw: sw, ne: ne });
};

const getHexagonDetails = async (hexId) => {
	return await axios.post(API_URL + "/hexagonDetails", { hexId: hexId });
};

const countries = async () => {
	return await axios.get(API_URL + "/countries");
}

const claim = async (hexId) => {
	return await axios.post(API_URL + "/claim", { hexId: hexId });
}

const exportedObject = {
	hexagons,
	getHexagonDetails,
	countries,
	claim
};

export default exportedObject;
