import axios from "./axiosConfig";
import config from "./config";

const API_URL = config.API_URL + "assignments";

const getAssignment = async () => {
	return await axios.get(API_URL + "/assignment");
};

const getAssignments = async () => {
    return await axios.get(API_URL + "/assignments");
};

const exportedObject = {
	getAssignment,
    getAssignments,
};

export default exportedObject;
