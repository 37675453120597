import axios from "./axiosConfig";
import config from "./config";

const API_URL = config.API_URL + "leaderboard";

const getInfluenceLeaderboard = async (leaderboardType, subregion) => {
	return await axios.post(API_URL + "/getInfluenceLeaderboard", { leaderboardType: leaderboardType, subregion: subregion });
};

const exportedObject = {
	getInfluenceLeaderboard,
};

export default exportedObject;
