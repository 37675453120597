import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AppBar, Toolbar, Box, Button, Menu, MenuItem, Typography, Chip, Tooltip,
  IconButton, Drawer, List, ListItem, ListItemButton, Divider
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

import usersService from '../services/users.service';

const Header = ({ user, setUser, onShowHowTo }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [timeUntilNextCredit, setTimeUntilNextCredit] = useState('');

  useEffect(() => {
    let timer;
    
    const calculateTimeUntilNextCredit = () => {
      const now = new Date();
      const nextCreditUpdate = new Date(user.nextCreditUpdateTime); // Parse the ISO string into a Date object

      const timeDifference = nextCreditUpdate - now;

      if (timeDifference > 0) {
        // Calculate hours, minutes, seconds
        const hours = Math.floor(timeDifference / (1000 * 60 * 60));
        const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

        setTimeUntilNextCredit(`${hours}h ${minutes}m ${seconds}s`);
      } else {
        setTimeUntilNextCredit('Available now');
      }
    };

    if (user && user.nextCreditUpdateTime) {
      calculateTimeUntilNextCredit();
      timer = setInterval(calculateTimeUntilNextCredit, 1000);
    }
  
    return () => {
      if (timer) clearInterval(timer);
    };
  }, [user]);


  const handleLogout = async () => {
    await usersService.logout();
    navigate("/login");
    setUser(null);
    handleClose();
  };

  const handleShowHowToModal = () => {
    onShowHowTo();
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMobileMenuOpen = () => {
    setMobileMenuOpen(true);
  };

  const handleMobileMenuClose = () => {
    setMobileMenuOpen(false);
  };

  const MobileMenu = () => (
    <Drawer anchor="right" open={mobileMenuOpen} onClose={handleMobileMenuClose}>
      <List>
        {user && (
          <>

            <ListItemButton disabled>
              {user.username}
            </ListItemButton>
            <ListItem>
              <Chip label={`Grid Gold: ${user.credits}`} color="primary" />
            </ListItem>
            <ListItemButton disabled style={{fontSize: 12}}>
              {timeUntilNextCredit}
            </ListItemButton>
            <Divider />
            <ListItemButton onClick={() => { handleMobileMenuClose(); navigate("/map"); }}>
              Map
            </ListItemButton>
            <ListItemButton onClick={() => { handleMobileMenuClose(); navigate("/leaderboard"); }}>
              Leaderboard
            </ListItemButton>
            <Divider />
            <ListItemButton onClick={() => { handleMobileMenuClose(); navigate("/profile"); }}>
              Profile
            </ListItemButton>
            <ListItemButton onClick={() => { handleShowHowToModal() }}>
              How-To
            </ListItemButton>
            <ListItemButton onClick={handleLogout}>
              Logout
            </ListItemButton>
          </>
        )}
        {!user && (
          <>
            <Divider />
            <ListItemButton onClick={() => { handleMobileMenuClose(); navigate("/login"); }}>
              Login
            </ListItemButton>
            <ListItemButton onClick={() => { handleMobileMenuClose(); navigate("/register"); }}>
              Register
            </ListItemButton>
          </>
        )}
      </List>
    </Drawer>
  );

  return (
    <AppBar position="static">
      <Toolbar>
        <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-start' }}>
          <Typography variant="h6" noWrap>
            Terra Dominia
          </Typography>
        </Box>
        {user && (
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'center' }}>
            <Button color="inherit" onClick={() => navigate("/map")}>Map</Button>
            <Button color="inherit" onClick={() => navigate("/leaderboard")}>Leaderboard</Button>
          </Box>
        )}
        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'flex-end' }}>
          {user ? (
            <>
              <Tooltip title={`Grid Gold: ${timeUntilNextCredit} | Wood: Coming soon | Crops: Coming soon`} arrow>
                <Chip label={`Grid Gold: ${user.credits}`} color="primary" style={{ marginRight: '20px' }} />
              </Tooltip>
              <Button variant='outlined' onClick={handleClick}>
                {user.username}
              </Button>
              <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                <MenuItem onClick={() => { handleClose(); navigate("/profile"); }}>
                  Profile
                </MenuItem>
                <MenuItem onClick={() => { handleShowHowToModal() }}>
                  How-To
                </MenuItem>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>
            </>
          ) : (
            <Button variant='outlined' onClick={() => navigate("/login")}>
              Account
            </Button>
          )}
        </Box>
        <Box sx={{ display: { xs: 'flex', sm: 'none' }, justifyContent: 'flex-end' }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleMobileMenuOpen}
          >
            <MenuIcon />
          </IconButton>
        </Box>
      </Toolbar>
      <MobileMenu />
    </AppBar>
  );
};

export default Header;
