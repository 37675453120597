import { useNavigate, Outlet } from "react-router-dom";

import usersService from '../services/users.service';

export default function ProtectedRoutes() {
    const navigate = useNavigate();
    usersService.getUser().then((oUser) => {
        if (oUser.status !== 200) {
            navigate("/login");
        } else {
            return <Outlet />;
        }
    });
}