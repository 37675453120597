import React, { useState, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { Container, CircularProgress, Box } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import Header from './components/Header';
import ErrorPage from './components/ErrorPage';
import HowToModal from './components/HowToModal';
import { SnackbarProvider } from 'notistack';

import usersService from './services/users.service';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#bf5700',
    },
    secondary: {
      main: '#2274a5',
    },
  }
});

function App() {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState('');
  const [technicalMessage, setTechnicalMessage] = useState('');
  const [hasError, setHasError] = useState(false);
  const navigate = useNavigate();

  const [showHowTo, setShowHowTo] = useState(false);

  const handleShowHowTo = () => {
    setShowHowTo(true);
  };

  useEffect(() => {
    usersService.getUser().then(response => {
      setUser(response.data.user);
      setIsLoading(false); // Indicates that user data is loaded

      if (response.data.message) {
        setMessage(response.data.message);
      }
    }).catch(err => {
      console.error('Could not fetch user data', err);
      setIsLoading(false); // Handle error, still set loading to false
      if (err.response && err.response.status === 401 && (window.location.href.includes("map") || window.location.href.includes("profile"))) {
        navigate('/'); // Redirect to login on 401 error
      } else if (err.response && err.response.status === 401) {
      } else if (err.response && err.response.status === 404) {
        usersService.logout();
      } else if (err?.message) {
        setTechnicalMessage(err.message || err);
        setHasError(true);
      }
    });
  }, [navigate]);

  const handleRetry = () => {
    window.location.reload(); // Reload the page
  };

  // Show loading indicator until user data is loaded
  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <ThemeProvider theme={darkTheme}>
      <SnackbarProvider maxSnack={7}>
        <CssBaseline />
        {hasError ? (
          <ErrorPage
            message="We're having trouble loading Terra Dominia. Please try again."
            technicalMessage={technicalMessage}
            onRetry={handleRetry}
          />
        ) : (
          <>
            <Header user={user} setUser={setUser} onShowHowTo={handleShowHowTo} />
            <Container maxWidth={false} disableGutters>
              <Box>
                {user && <HowToModal user={user} open={showHowTo} setOpen={setShowHowTo} />}
                <Outlet context={[user, setUser, message, setMessage]} />
              </Box>
            </Container>
          </>
        )}
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
