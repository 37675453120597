import React, { useEffect, useState } from 'react';
import assignmentsService from '../../services/assignments.service';
import { Card, CardContent, Typography } from '@mui/material';

const Assignments = () => {
    const [assignments, setAssignments] = useState([]);

    useEffect(() => {
        const fetchAssignments = async () => {
            try {
                const fetchedAssignments = await assignmentsService.getAssignments();
                setAssignments(fetchedAssignments.data.map(assignment => ({
                    ...assignment,
                    displayStartDate: new Date(assignment.startDate).toLocaleDateString(),
                    displayEndDate: new Date(assignment.endDate).toLocaleDateString()
                })));
            } catch (error) {
                console.error('Error fetching assignments:', error);
            }
        };

        fetchAssignments();
    }, []);

    const isActiveAssignment = (assignment) => {
        const today = new Date();
        const startDate = new Date(assignment.startDate);
        const endDate = new Date(assignment.endDate);
        return startDate <= today && endDate >= today;
    };

    return (
        <div style={{ padding: '20px' }}>
            <Typography variant="h6" gutterBottom>Active Assignments</Typography>
            {assignments.length > 0 ? assignments.map(assignment => {
                const active = isActiveAssignment(assignment);
                return (
                    <Card 
                        key={assignment.id}
                        style={{ 
                            marginBottom: '20px',
                            backgroundColor: active ? '#bf5700' : '', // Theme's primary color for active assignment
                            color: active ? 'white' : 'grey' // White text for active, grey for inactive
                        }}
                    >
                        <CardContent>
                            <Typography variant="h6" style={{ color: 'inherit' }}>{assignment.shortText}</Typography>
                            <Typography variant="body2" style={{ color: 'inherit' }} gutterBottom>{assignment.longText}</Typography>
                            <Typography variant="body2" style={{ color: 'inherit' }}>Start: {assignment.displayStartDate}</Typography>
                            <Typography variant="body2" style={{ color: 'inherit' }} gutterBottom>End date: {assignment.displayEndDate}</Typography>
                            <Typography variant="body2" style={{ color: 'inherit' }}>Stop condition: {assignment.stopCondition}</Typography>
                            <Typography variant="body2" style={{ color: 'inherit' }}>Reward: {assignment.rewardText}</Typography>
                        </CardContent>
                    </Card>
                );
            }) : <Typography>No assignments available.</Typography>}
        </div>
    );
}

export default Assignments;
