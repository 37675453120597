import React, { useState, useEffect } from 'react';
import { useOutletContext, useParams } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import Achievements from '../components/Profile/Achievements';
import Assignments from '../components/Profile/Assignments';
import Territory from '../components/Profile/Territory';
import Allies from '../components/Profile/Allies';

import { Avatar, Card, CardContent, Typography, Button, Grid, Box, Chip, Stack } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';
import MapIcon from '@mui/icons-material/Public';
import PeopleIcon from '@mui/icons-material/People';
import PersonIcon from '@mui/icons-material/Person';

import userService from '../services/users.service';

const Profile = () => {
  const [user, setUser] = useOutletContext();

  const { externalUser, sectionName } = useParams();
  const [section, setSection] = useState('assignments');
  const [profileUser, setProfileUser] = useState(null);
  const [externalUserId, setExternalUserId] = useState(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    // Set the section based on the URL parameter, default to 'assignments'
    setSection(sectionName || 'assignments');
  }, [sectionName]);

  useEffect(() => {
    const fetchUserProfile = async (id) => {
      try {
        // Fetch user profile based on the provided ID
        const userData = await userService.getExternalUser(id);
        setExternalUserId(userData.data.user.userId);
        setProfileUser(userData.data.user);
      } catch (error) {
        console.error('Error fetching user profile:', error);
      }
    };

    if (externalUser) {
      fetchUserProfile(externalUser);
    } else {
      // If no userId in URL, use logged-in user's profile
      setProfileUser(user);
      setExternalUserId(null);
    }
  }, [externalUser, user]);

  const avatarIcon = <PersonIcon style={{ fontSize: isMobile ? 50 : 100 }} />;

  const renderSection = () => {
    switch (section) {
      case 'achievements':
        return <Achievements userId={externalUserId}/>;
      case 'assignments':
        return <Assignments userId={externalUserId}/>;
      case 'territory':
        return <Territory userId={externalUserId} />;
      case 'allies':
        return <Allies userId={externalUserId} />;
      default:
        return <Achievements userId={externalUserId} />;
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: '100vh',
      }}
    >
      {(profileUser &&
        <Card sx={{ width: isMobile ? '90%' : '75%', m: 3, overflowY: 'auto' }}>
          <CardContent>
            <Grid container spacing={3} alignItems="center" justifyContent="space-between">

              {/* Avatar and User Details */}
              <Grid item xs={12} sm={4} textAlign={"center"}>
                <Avatar
                  alt="User"
                  src={profileUser.profileImage || ''}
                  sx={{ width: 100, height: 100, border: '2px solid white', mb: 2, mx: 'auto' }}
                >
                  {avatarIcon}
                </Avatar>
                <Typography variant="h4">{profileUser.username}</Typography>
                <Typography variant="subtitle2">{profileUser.email}</Typography>
              </Grid>

              {/* Balances */}
              <Grid item xs={12} sm={3}>
                <Stack spacing={1} alignItems="left">
                  <Chip label={`Grid Gold: ${profileUser.credits}`} variant="outlined" color="primary" />
                  <Chip label={`Wood: Coming soon`} variant="outlined" color="primary" />
                  <Chip label={`Crops: Coming soon`} variant="outlined" color="primary" />
                </Stack>
              </Grid>

              {/* Faction */}
              <Grid item xs={12} sm={4} textAlign='center'>
                <Box sx={{ mb: 2, display: 'inline-block' }}>
                  <img
                    src={`/${profileUser.orgLogo}`}
                    alt={profileUser.orgName}
                    style={{ width: '200px', height: 'auto' }}
                  />
                  <Chip label={profileUser.orgName} variant="outlined" color="primary" />
                </Box>
              </Grid>
            </Grid>
            <Grid container spacing={3} mt={3}>
              <Grid item xs={12} sm={3}>
                <Button
                  variant={section === 'assignments' ? 'contained' : 'outlined'}
                  color={section === 'assignments' ? 'primary' : 'inherit'}
                  startIcon={<CrisisAlertIcon />}
                  fullWidth
                  onClick={() => setSection('assignments')}
                >
                  Assignments
                </Button>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Button
                  variant={section === 'achievements' ? 'contained' : 'outlined'}
                  color={section === 'achievements' ? 'primary' : 'inherit'}
                  startIcon={<StarIcon />}
                  fullWidth
                  onClick={() => setSection('achievements')}
                >
                  Achievements
                </Button>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Button
                  variant={section === 'territory' ? 'contained' : 'outlined'}
                  color={section === 'territory' ? 'primary' : 'inherit'}
                  startIcon={<MapIcon />}
                  fullWidth
                  onClick={() => setSection('territory')}
                >
                  Territory
                </Button>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Button
                  variant={section === 'allies' ? 'contained' : 'outlined'}
                  color={section === 'allies' ? 'primary' : 'inherit'}
                  startIcon={<PeopleIcon />}
                  fullWidth
                  onClick={() => setSection('allies')}
                >
                  Allies
                </Button>
              </Grid>
            </Grid>
            <Box
              sx={{
                mt: 3,
                p: 2,
                border: '2px solid #FFFFFF',
                borderRadius: '8px',
              }}
            >
              {renderSection()}
            </Box>
          </CardContent>
        </Card>
      )}
    </Box>
  );
};

export default Profile;

