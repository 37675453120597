import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams, useOutletContext } from 'react-router-dom';
import mapboxgl from 'mapbox-gl';

import Button from '@mui/material/Button';
import PublicIcon from '@mui/icons-material/Public';
import ApartmentIcon from '@mui/icons-material/Apartment';
import CloseIcon from '@mui/icons-material/Close'; // Import Close icon

import Alert from '@mui/material/Alert';

import { useSnackbar } from 'notistack';

import mapService from '../services/map.service';
import assignmentsService from '../services/assignments.service';

import { initializeMap } from '../components/MapBoxMap/MapInit';
import { attachMapListeners } from '../components/MapBoxMap/MapListeners';
import '../styling/buyHexPopup.css';

let hoveredHexId = null;  // Store the ID of the hovered hexagon

const popup = new mapboxgl.Popup({
  closeButton: false,
  closeOnClick: false
});

const Map = () => {

  const [user, setUser, message, setMessage] = useOutletContext();
  const [assignment, setAssignment] = useState(null);
  const [showAlert, setShowAlert] = useState(true); // State to control the visibility of the alert

  const navigate = useNavigate();
  const { hexagonId } = useParams();

  // Using notistack's useSnackbar hook
  const { enqueueSnackbar } = useSnackbar();

  const mapRef = useRef(null);  // Create a ref to store the map instance
  let oCountry;

  useEffect(() => {
    // Fetch the current active assignment
    const fetchAssignment = async () => {
      try {
        const currentAssignment = await assignmentsService.getAssignment();
        setAssignment(currentAssignment.data); // Set the assignment in the state
      } catch (error) {
        console.error('Error fetching assignment:', error);
      }
    };

    fetchAssignment(); // Call the function to fetch the assignment


  }, []);

  useEffect(() => {
    if (message) {
      enqueueSnackbar(message, { variant: 'info' });
      setMessage(null);
    }
  }, [message]);

  useEffect(() => {

    if (mapRef.current) {
      return;  // If map is already initialized, do nothing
    }

    const map = initializeMap(mapRef, hexagonId);  // Initialize the map
    mapRef.current = map;  // Store the map instance in the ref

    const performClaimOperation = async (clickedFeature, popup) => {

      const action = () => (
        <Button
          variant="text"
          sx={{ color: 'white' }}
          onClick={() => { navigate("/profile/achievements"); }}>
          Go
        </Button>
      );

      try {
        const oClaimedHex = await mapService.claim(clickedFeature.properties.hexId);

        // Check if the response includes updated user data
        if (oClaimedHex.data.user) {
            setUser(oClaimedHex.data.user); // Update the user state with the new data
        }

        // Check if the response includes updated assignment data
        if (oClaimedHex.data.assignment) {
          setAssignment(oClaimedHex.data.assignment); // Update the assignment state with the new data
        }

        // Use enqueueSnackbar for notifications
        const aMessages = [];
        aMessages.push({ message: oClaimedHex.data.message, variant: 'success', action: null });
        oClaimedHex.data.achievements.forEach(oAchievement => {

          const variant = oAchievement.status === 'Completed' ? 'success' : 'info';
          const message = `Achievement ${oAchievement.status}: ${oAchievement.title} `;

          aMessages.push({ message: message, variant: variant, action: action });
        });

        aMessages.forEach(oMessage => {
          enqueueSnackbar(oMessage.message, { variant: oMessage.variant, action: oMessage.action });
        });

        updateHexagons(mapRef.current);
      } catch (error) {
        console.error('Error:', error);

        // Use enqueueSnackbar for error notifications
        enqueueSnackbar(error.response.data.message, { variant: 'error' });
      }

      // Close the popup
      popup.remove();
    };

    attachMapListeners(map, popup, performClaimOperation);

    map.on('moveend', () => {
      const zoom = map.getZoom();

      if (zoom < 12) {
        if (map.getLayer('hex-fill')) {
          map.removeLayer('hex-fill');
          map.removeLayer('hex-outline');
          map.removeSource('hexagon-source');
        }
      } else if (zoom >= 12) {
        updateHexagons(map);
      }

      if (zoom > 6) {
        if (map.getLayer('countries-fill')) {
          map.removeLayer('countries-fill');
          map.removeLayer('countries-outline');
          map.removeSource('countries-source');
        }
      } else if (zoom <= 6) {
        if (!map.getLayer('countries-fill')) {
          mapService.countries().then((oData) => {
            oCountry = oData;
            updateCountries(map);
          });
        }
      }
    });

    // When the user moves their mouse over the hex-fill layer, we'll update the
    // feature state for the feature under the mouse.
    map.on('mousemove', 'hex-fill', (e) => {
      if (e.features.length > 0) {
        if (hoveredHexId) {
          map.setFeatureState({ source: 'hexagon-source', id: hoveredHexId }, { hover: false });
        }
        hoveredHexId = e.features[0].properties.id; // use `id` directly from feature
        map.setFeatureState({ source: 'hexagon-source', id: hoveredHexId }, { hover: true });
      }
    });

    // When the mouse leaves the hex-fill layer, update the feature state of the
    // previously hovered feature.
    map.on('mouseleave', 'hex-fill', () => {
      if (hoveredHexId !== null && map.getSource('hexagon-source')) {
        map.setFeatureState(
          { source: 'hexagon-source', id: hoveredHexId },
          { hover: false }
        );
      }
      hoveredHexId = null;
    });

    map.on('load', () => {
      if (hexagonId) {
        // Update the hexagons
        if (!map.getLayer('hex-fill')) {
          updateHexagons(map);
        }
      } else {
        mapService.countries().then((oData) => {
          oCountry = oData;
          updateCountries(map);
        });
      }
    })

  }, [hexagonId]);

  // Function to handle the close action of the alert
  const handleCloseAlert = () => {
    setShowAlert(false); // Hide the alert
  };

  const zoomToCountry = () => {
    if (mapRef.current) {
      mapRef.current.flyTo({
        center: [-98.3763987583968, 38.586739144774626],
        zoom: 3.5,
      });
    }
  };

  const zoomToCity = () => {
    if (mapRef.current) {
      mapRef.current.flyTo({
        zoom: 12.5,
      });
    }
  };

  const updateCountries = async (map) => {
    let oCountriesSource = map.getSource('countries-source');
    if (!oCountriesSource) {

      map.addSource('countries-source', {
        type: 'geojson',
        data: oCountry.data
      });

      map.addLayer({
        id: 'countries-fill',
        type: 'fill',
        source: 'countries-source',
        layout: {},
        paint: {
          'fill-color': ['get', 'orgColor'],  // Data-driven style based on the 'color' property
          'fill-opacity': 0.5
        },
      });

      map.addLayer({
        id: 'countries-outline',
        type: 'line',
        source: 'countries-source',
        layout: {},
        paint: {
          'line-color': '#000',  // Black outline color
          'line-width': 1,       // Outline width
          'line-opacity': 1    // Outline opacity
        }
      });
    }
  };

  const updateHexagons = async (map) => {
    const bounds = map.getBounds();
    const sw = bounds.getSouthWest();
    const ne = bounds.getNorthEast();

    const aHexagons = await mapService.hexagons(sw, ne);

    const hexagonGeoJson = {
      type: 'FeatureCollection',
      features: aHexagons.data.features
    };

    const hubOutlineGeoJson = {
      type: 'FeatureCollection',
      features: aHexagons.data.hubFeatures
    };

    let oHexagonOutlineSource = map.getSource('hexagon-outline-source');
    if (!oHexagonOutlineSource) {
      map.addSource('hexagon-outline-source', {
        type: 'geojson',
        data: hubOutlineGeoJson,
      });

      // Layer for the hub outline (black)
      map.addLayer({
        id: 'hub-outline',
        type: 'line',
        source: 'hexagon-outline-source',
        layout: {},
        paint: {
          'line-color': '#FFD700',  // Black outline color
          'line-width': 3,       // Outline width
          // 'line-opacity': 0.1    // Outline opacity
        }
      });
    } else {
      try {
        // Try updating source data
        map.getSource('hexagon-outline-source').setData(hubOutlineGeoJson);
      } catch (e) {
        console.error('Error updating hexagon outline source:', e);
      }
    }


    let oHexagonSource = map.getSource('hexagon-source');
    if (!oHexagonSource) {
      map.addSource('hexagon-source', {
        type: 'geojson',
        data: hexagonGeoJson,
      });

      // Layer for the fill (transparent)
      map.addLayer({
        id: 'hex-fill',
        type: 'fill',
        source: 'hexagon-source',
        layout: {},
        paint: {
          'fill-color': ['get', 'orgColor'], // Fetch color from the "color" property in the feature
          'fill-opacity': [
            'case',
            ['boolean', ['feature-state', 'hover'], false],
            0.3,
            ['get', 'opacity'] // Fetch opacity from the "opacity" property in the feature
          ]
        },
      });

      // Layer for the outline (black)
      map.addLayer({
        id: 'hex-outline',
        type: 'line',
        source: 'hexagon-source',
        layout: {},
        paint: {
          'line-color': '#000',  // Black outline color
          'line-width': 1,       // Outline width
          'line-opacity': 0.1    // Outline opacity
        }
      });
    } else {
      try {
        // Try updating source data
        map.getSource('hexagon-source').setData(hexagonGeoJson);
      } catch (e) {
        console.error('Error updating hexagon source:', e);
      }
    }
  };

  return (
    <div id="mapContainer" style={{ width: '100%', height: 'calc(100vh - 64px)' }}>
      <div
        title="Zoom to Country Level"
        style={{
          position: 'absolute',
          top: '10px',
          left: '10px',
          zIndex: 1, // ensures the button stays on top of the map layers
          backgroundColor: '#bf5700',
          width: '40px',  // Adjust as needed
          height: '40px', // Adjust as needed
          borderRadius: '50%', // Makes the button round
          display: 'flex',
          alignItems: 'center', // Centers the icon vertically
          justifyContent: 'center', // Centers the icon horizontally
          cursor: 'pointer'
        }}
        onClick={zoomToCountry}
      >
        <PublicIcon style={{ color: 'white', fontSize: 24 }} /> {/* Adjust fontSize for desired icon size */}

      </div>
      <div
        title="Zoom to Grid Level"
        style={{
          position: 'absolute',
          top: '60px',  // Adjust the top value to position it below the FlagIcon button
          left: '10px',
          zIndex: 1, // ensures the button stays on top of the map layers
          backgroundColor: '#bf5700',
          width: '40px',  // Adjust as needed
          height: '40px', // Adjust as needed
          borderRadius: '50%', // Makes the button round
          display: 'flex',
          alignItems: 'center', // Centers the icon vertically
          justifyContent: 'center', // Centers the icon horizontally
          cursor: 'pointer'
        }}
        onClick={zoomToCity}
      >
        <ApartmentIcon style={{ color: 'white', fontSize: 24 }} /> {/* Adjust fontSize for desired icon size */}
      </div>

      {assignment && showAlert && (
        <div style={{
          position: 'fixed',
          bottom: '30px', // Padding from the bottom
          right: '20px', // Padding from the left
          zIndex: 2, // Ensure it's above other elements
        }}>
          <Alert
            severity="info"
            style={{ color: '#bf5700' }} // Set text color to primary color
            action={
              <Button onClick={handleCloseAlert} style={{ color: '#bf5700' }}> {/* Set button color to primary color */}
                <CloseIcon style={{ color: '#bf5700' }} /> {/* Set icon color to primary color */}
              </Button>
            }
          >
            {assignment.shortText}
          </Alert>
        </div>
      )}
    </div>
  );
};

export default Map;