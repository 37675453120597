import React from 'react';
import { Button, Typography, Container, Box, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Main = () => {
  const navigate = useNavigate();

  return (
    <Container
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Grid container spacing={2} alignItems="center">
        {/* Image Section */}
        <Grid item xs={12} sm={6}>
          <img
            src={`${process.env.PUBLIC_URL}/TerraDominiaMain.png`}
            alt="Terra Dominia"
            style={{
              maxWidth: '100%',
              borderRadius: '8px',
            }}
          />
        </Grid>
        
        {/* Text and Button Section */}
        <Grid item xs={12} sm={6}>
          <Box
            sx={{
              backgroundColor: 'rgba(0, 0, 0, 0.7)',
              padding: '20px',
              borderRadius: '8px',
            }}
          >
            <Typography variant="h2" color="textPrimary" gutterBottom>
              Welcome to Terra Dominia
            </Typography>
            <Box mt={3}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={() => navigate('/register')}
                sx={{ marginRight: '10px' }}
              >
                Sign Up
              </Button>
              <Button
                variant="outlined"
                color="primary"
                size="large"
                onClick={() => navigate('/login')}
              >
                Sign In
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Main;
