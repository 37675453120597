import mapboxgl from 'mapbox-gl';
import * as h3 from 'h3-js';

import mapService from '../../services/map.service.js';

export const attachMapListeners = (map, popup, performClaimOperation) => {

    function getPentagonCoordinates(resolution) {
        const pentagonIds = h3.getPentagons(resolution);

        let aPentagonCoordinates = [];
        for (let i = 0; i < pentagonIds.length; i++) {
            {
                let oPentagonCoordinates = h3.cellToLatLng(pentagonIds[i]);
                aPentagonCoordinates.push(oPentagonCoordinates);
            }
        }
        return aPentagonCoordinates;
    }

    map.on('load', () => {
        const pentagonCoordinates = getPentagonCoordinates(9);
        for (let i = 0; i < pentagonCoordinates.length; i++) {
            // Create a marker for each pentagon and add it to the map
            new mapboxgl.Marker()
                .setLngLat([pentagonCoordinates[i][1], pentagonCoordinates[i][0]])  // Note the order: [longitude, latitude]
                .addTo(map);
        }

        // // Add terrain source, with slight exaggeration
        // map.addSource('mapbox-dem', {
        //     'type': 'raster-dem',
        //     'url': 'mapbox://mapbox.terrain-rgb',
        //     'tileSize': 512,
        //     'maxzoom': 14
        // });
        // map.setTerrain({ 'source': 'mapbox-dem', 'exaggeration': 1.5 });

        // map.addSource('mapbox-terrain', {
        //     type: 'vector',
        //     url: 'mapbox://mapbox.mapbox-terrain-v2'  // Mapbox Terrain v2 source
        // });

        // // Add the landcover data as a layer
        // map.addLayer({
        //     'id': 'landcover-data',
        //     'type': 'fill',
        //     'source': 'mapbox-terrain',
        //     'source-layer': 'landcover',  // Use the 'landcover' layer from the terrain v2 data
        //     'layout': {},
        //     'paint': {
        //         'fill-color': '#088',      // Color for the terrain
        //         'fill-opacity': 0       // Opacity for the terrain
        //     }
        // });

        // // Add the landuse layer from Mapbox Streets v8
        // map.addSource('mapbox-streets', {
        //     'type': 'vector',
        //     'url': 'mapbox://mapbox.mapbox-streets-v8'
        // });

        // map.addLayer({
        //     'id': 'landuse-layer',
        //     'type': 'fill',
        //     'source': 'mapbox-streets',
        //     'source-layer': 'landuse',
        //     'paint': {
        //         'fill-color': '#eee',  // Example color, you can customize as needed
        //         'fill-opacity': 0.5
        //     }
        // });

        // map.addLayer({
        //     'id': 'water-layer',
        //     'type': 'fill',
        //     'source': 'mapbox-streets',
        //     'source-layer': 'water',
        //     'paint': {
        //         'fill-color': '#eee',  // Example color, you can customize as needed
        //         'fill-opacity': 0.5
        //     }
        // });

        // map.addLayer({
        //     "id": "poi-layer",
        //     "type": "symbol",
        //     "source": "mapbox-streets",
        //     "source-layer": "poi_label",
        //     "layout": {
        //         "text-field": ["get", "name"], // Display the name property of the POI
        //         "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
        //         "text-size": 10
        //     },
        //     "paint": {
        //         "text-color": "#666666"
        //     }
        // });

    });

    // Display a popup when hovering over the landcover layer
    map.on('mousemove', (e) => {
        const zoom = map.getZoom();
        if (zoom >= 12) {
            // const coordinates = e.lngLat;

            // // Query features from the contour layer at the current mouse position with a 10px radius to detect closest features around the hovered point
            // const radius = 50; // Adjust this value to change the search radius

            // // Creating a bounding box around the clicked point
            // const bbox = [
            //     [e.point.x - radius, e.point.y - radius],
            //     [e.point.x + radius, e.point.y + radius]
            // ];

            // // Query features from both landcover and layer at the current mouse position
            // const landcoverFeatures = map.queryRenderedFeatures(e.point, { layers: ['landcover-data'] });
            // const landuseFeatures = map.queryRenderedFeatures(e.point, { layers: ['landuse-layer'] });
            // const waterFeatures = map.queryRenderedFeatures(e.point, { layers: ['water-layer'] });
            // const poiFeatures = map.queryRenderedFeatures(bbox, { layers: ['poi-layer'] });

            // // Extract data for the popup
            // const landcoverClass = landcoverFeatures.length ? `Landcover Class: ${landcoverFeatures[0].properties.class}` : '';
            // const landuse = landuseFeatures.length ? `Land Use: ${landuseFeatures[0].properties.class}` : '';
            // const poiNames = poiFeatures.map(feature => feature.properties.name);
            // const poi = poiNames.length ? `Points of Interest: ${poiNames.join(', ')}` : '';
            // const water = waterFeatures.length ? `Water: ${waterFeatures[0].properties.class}` : '';
            // const elevation = `Actual elevation: ${Math.floor(
            //     map.queryTerrainElevation([e.lngLat.lng, e.lngLat.lat], { exaggerated: false })
            // )}`;

            // // Combine data for the popup content
            // const content = `
            //     ${landcoverClass} <br>
            //     ${landuse} <br>
            //     ${elevation} <br>
            //     ${poi} <br>
            //     ${water}
            //   `;

            // // Set popup content and location
            // popup.setLngLat(coordinates)
            //     .setHTML(content)
            //     .addTo(map);
        }
    });

    // Remove the popup when the mouse leaves the landcover layer
    map.on('mouseleave', 'landcover-data', () => {
        // popup.remove();
    });

    // Add a listener to 'click' event on the 'countries-fill' layer
    map.on('click', 'countries-fill', function (e) {
        var features = map.queryRenderedFeatures(e.point, {
            layers: ['countries-fill']
        });

        if (!features.length) {
            return;
        }

        // Assuming the first feature is the one you're interested in
        var clickedFeature = features[0];

        // Get capital coordinates
        try {
            const capitalCoordinates = JSON.parse(clickedFeature.properties.capitalCoordinates);
            // Security consideration: Ensure that capitalCoordinates are indeed numbers
            // and not code that can perform malicious actions
            if (Array.isArray(capitalCoordinates) && capitalCoordinates.length === 2
                && typeof capitalCoordinates[0] === 'number'
                && typeof capitalCoordinates[1] === 'number') {
                map.flyTo({
                    center: [capitalCoordinates[1], capitalCoordinates[0]],
                    zoom: 12.5,
                });
            } else {
                console.error("Invalid capital coordinates");
            }
        } catch (error) {
            console.error("Error parsing capital coordinates:", error);
        }
    });

    // Listen for the 'click' event on the map
    map.on('click', 'hex-fill', function (e) {
        // Use queryRenderedFeatures to get an array of geoJSON features at point
        var features = map.queryRenderedFeatures(e.point, {
            layers: ['hex-fill']
        });

        if (!features.length) {
            return;
        }

        // If there is a feature, let's get the first one (assuming each point will only fall within one polygon)
        var clickedFeature = features[0];

        // Your clicked feature's coordinates and properties are now in clickedFeature
        // const coordinates = clickedFeature.geometry.coordinates;
        const properties = clickedFeature.properties;

        // fetch hexagon details from getHexagonDetails
        const hexId = features[0].properties.hexId;
        
        let oHexCoordinates = h3.cellToLatLng(hexId);

        // // Query features from the contour layer at the current mouse position with a 10px radius to detect closest features around the hovered point
        // const radius = 50; // Adjust this value to change the search radius

        // // Creating a bounding box around the clicked point
        // const bbox = [
        //     [e.point.x - radius, e.point.y - radius],
        //     [e.point.x + radius, e.point.y + radius]
        // ];

        // // Query features from both landcover and layer at the current mouse position
        // const landcoverFeatures = map.queryRenderedFeatures(e.point, { layers: ['landcover-data'] });
        // const landuseFeatures = map.queryRenderedFeatures(e.point, { layers: ['landuse-layer'] });
        // const waterFeatures = map.queryRenderedFeatures(e.point, { layers: ['water-layer'] });
        // const poiFeatures = map.queryRenderedFeatures(bbox, { layers: ['poi-layer'] });

        // // Extract data for the popup
        // const landcoverClass = landcoverFeatures.length ? `Landcover Class: ${landcoverFeatures[0].properties.class}` : '';
        // const landuse = landuseFeatures.length ? `Land Use: ${landuseFeatures[0].properties.class}` : '';
        // const poiNames = poiFeatures.map(feature => feature.properties.name);
        // const poi = poiNames.length ? `Points of Interest: ${poiNames.join(', ')}` : '';
        // const water = waterFeatures.length ? `Water` : '';

        mapService.getHexagonDetails(hexId).then((hexagonDetails) => {
            hexagonDetails = hexagonDetails.data;

            // Create and add the popup
            if (properties.username) {
                const popup = new mapboxgl.Popup()
                    .setLngLat([oHexCoordinates[1], oHexCoordinates[0]])
                    .setHTML(`
                    <div class="custom-popup">
                        <h3>Hexagon</h3>
                        Owner: <a href="/profile/user/${properties.userId}" style="color: #bf5700;">${properties.username}</a> <br>
                        Type: ${properties.type} <br>
                        Hub Type: ${properties.hubType} <br>
                        <h3>Location</h3>
                        Country: ${properties.country} <br>
                        Subregion: ${properties.subregion} <br>
                        City: ${properties.city} <br>
                        <h3>Land</h3>
                        Elevation: ${properties.elevation} <br>
                        Land Cover: ${properties.land_cover} <br>
                        Land Use: ${properties.land_use} <br>              
                    </div>
                    `)
                    .addTo(map);
            } else {
                const popup = new mapboxgl.Popup()
                    .setLngLat([oHexCoordinates[1], oHexCoordinates[0]])
                    .setHTML(`
                    <div class="custom-popup">
                        <h3>Hexagon </h3>
                        Price: ${properties.price} <br>
                        <h3>Location</h3>
                        Country: ${hexagonDetails.country} <br>
                        Subregion: ${hexagonDetails.subregion} <br>
                        City: ${hexagonDetails.city} <br>
                        <h3>Land Details</h3>
                        Elevation: ${hexagonDetails.elevation} <br>
                        Land Cover: ${hexagonDetails.land_cover} <br>
                        Land Use: ${hexagonDetails.land_use} <br>
                        <button id="claim-button">Claim</button>
                    </div>
                    `)
                    .addTo(map);

                setTimeout(() => {
                    const claimButton = document.getElementById('claim-button');

                    if (claimButton) {
                        claimButton.addEventListener('click', function () {
                            performClaimOperation(clickedFeature, popup);
                        });
                    }
                }, 100);  // 100 milliseconds delay, adjust as needed
            }

        });
    });

    // When the user moves their mouse over the countries-fill layer, show the popup
    map.on('mousemove', 'countries-fill', (e) => {
        // Only show the first feature (in case there are overlapping features)
        const feature = e.features[0];

        // Set the popup content to the country name (or any other property you want)
        popup.setHTML(feature.properties.NAME_1 + "<br>" + feature.properties.orgName);

        // Set the popup location to the mouse position
        popup.setLngLat(e.lngLat).addTo(map);
    });

    // Hide the popup when the mouse leaves the countries-fill layer
    map.on('mouseleave', 'countries-fill', () => {
        popup.remove();
    });

};
