import { Box, Typography, Button } from '@mui/material';

const ErrorPage = ({ message, technicalMessage, onRetry }) => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100vh">
      <Typography variant="h4" gutterBottom>
        Oops! Something went wrong.
      </Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
        {message}
      </Typography>
        <Typography variant="body2" sx={{ mb: 2 }}>
            {technicalMessage}
        </Typography>
      <Button variant="contained" color="primary" onClick={onRetry} >
        Try Again
      </Button>
    </Box>
  );
};

export default ErrorPage;