import axios from "./axiosConfig";
import config from "./config";

const API_URL = config.API_URL + "achievements";

const getAchievements = async (id) => {
	return await axios.post(API_URL + "/achievements", {id: id});
};

const claimAchievement = async (id) => {
	return await axios.post(API_URL + "/claimAchievement", {
		id,
	});
};

const exportedObject = {
	getAchievements,
	claimAchievement
};

export default exportedObject;
