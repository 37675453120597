// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import * as h3 from 'h3-js';

export const initializeMap = (mapRef, hexagonId) => {
  let zoom = 3.5, center = [-98.3763987583968, 38.586739144774626];
  
  if (hexagonId) {
    // Convert the hexId to the center coordinates
    const [lat, lon] = h3.cellToLatLng(hexagonId);
    const centerCoordinates = [lon, lat];
    // Set the map center and zoom level directly
    center = centerCoordinates;
    zoom = 12.5;
  }

  // Initialize the map
  mapboxgl.accessToken = 'pk.eyJ1IjoiaWVta2VrIiwiYSI6ImNsMXFocG9sMzBqbTAzZG9heDd1M2thcmMifQ.X7qebXuJB-_9crl1Yb-x8g';
  const map = new mapboxgl.Map({
    container: 'mapContainer',
    style: 'mapbox://styles/iemkek/clod2uxrc015v01pk3wkhdu59',
    center: center,
    zoom: zoom,
  });

  // Add geocoder search bar to the map
  map.addControl(
    new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl: mapboxgl
    })
  );

  // Add zoom and rotation controls to the map.
  map.addControl(new mapboxgl.NavigationControl());

  mapRef.current = map;  // Store the map instance in the ref
  return map;
};
