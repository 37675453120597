import React, { useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';

import usersService from '../../services/users.service';
import { Card, CardContent, Typography, CardActions, Button, Grid, Select, MenuItem, FormControl, InputLabel } from '@mui/material';

const Territory = ( externalUser ) => {
    const [user, setUser] = useOutletContext();
    const [hexagons, setHexagons] = useState(null);
    const [selection, setSelection] = useState('Hexagon'); // Default selection
    const navigate = useNavigate();

    useEffect(() => {
        const targetUserId = externalUser.userId || user.userId;
        const fetchHexagons = async () => {
            try {
                const response = await usersService.getUserHexagons(selection, targetUserId);
                setHexagons(response.data);
            } catch (error) {
                console.error('Error fetching hexagons:', error);
            }
        };

        fetchHexagons();
    }, [selection]); // Add selection to the dependency array

    const formatDate = (timestamp) => {
        const date = new Date(timestamp);
        return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
    };

    const handleVisit = (hexagonId) => {
        navigate(`/map/${hexagonId}`);
    };

    const handleSelectionChange = (event) => {
        setSelection(event.target.value);
    };

    // Sort hexagons by groupId, treating null as a separate group
    const sortedHexagons = (Array.isArray(hexagons) ? hexagons : [])
        .filter(hexagon => hexagon.type === selection)
        .sort((a, b) => {
            if (a.groupId === null) return -1;
            if (b.groupId === null) return 1;
            return a.groupId.localeCompare(b.groupId);
        });

    let lastGroupId = null;

    // Function to check if there are hexagons of the selected type
    const hasHexagons = sortedHexagons && sortedHexagons.length > 0;

    return (
        <div>
            <FormControl fullWidth style={{ margin: '10px 0' }}>
                <InputLabel id="selection-label">Select Type</InputLabel>
                <Select
                    labelId="selection-label"
                    id="selection"
                    value={selection}
                    label="Select Type"
                    onChange={handleSelectionChange}
                >
                    <MenuItem value="Hexagon">Hexagons</MenuItem>
                    <MenuItem value="Cluster">Clusters</MenuItem>
                    <MenuItem value="Hub">Hubs</MenuItem>
                </Select>
            </FormControl>
            {hasHexagons ? sortedHexagons.map((hexagon, index) => {
                const isNewGroup = hexagon.groupId !== lastGroupId;
                lastGroupId = hexagon.groupId;

                return (
                    <div key={hexagon.hexId}>
                        {isNewGroup && index !== 0 && (
                            <hr style={{ marginTop: '20px' }} /> // Visual separator
                        )}
                        <Card style={{ margin: '10px' }}>
                            <CardContent>
                                <Grid container spacing={3}>
                                    <Grid item xs={4}>
                                        <Typography variant="h6" component="h2" style={{ fontWeight: 'bold' }}>
                                            {hexagon.country}
                                        </Typography>
                                        <Typography color="textSecondary">
                                            {hexagon.subregion}
                                        </Typography>
                                        <Typography color="textSecondary">
                                            {hexagon.city}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={5}>
                                        {hexagon.type === 'Hexagon' && (
                                            <div>
                                                <Typography color="textSecondary">
                                                    Land Cover: {hexagon.land_cover}
                                                </Typography>
                                                <Typography color="textSecondary">
                                                    Land Use: {hexagon.land_use}
                                                </Typography>
                                                <Typography color="textSecondary" style={{ marginBottom: '10px' }}>
                                                    Elevation: {hexagon.elevation}m
                                                </Typography>
                                            </div>
                                        )}
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography color="textSecondary">
                                            {formatDate(hexagon.createdAt)}
                                        </Typography>
                                        <Typography color="textSecondary">
                                            {hexagon.type}
                                        </Typography>
                                        {hexagon.type === 'Hub' && (
                                            <Typography color="textSecondary">
                                                {hexagon.hubType}
                                            </Typography>
                                        )}
                                    </Grid>
                                </Grid>
                            </CardContent>
                            <CardActions style={{ justifyContent: 'flex-end' }}>
                                <Button size="small" onClick={() => handleVisit(hexagon.hexId)}>Visit</Button>
                            </CardActions>
                        </Card>
                    </div>
                );
            }) : (
                <Typography variant="subtitle1" style={{ textAlign: 'center', marginTop: '20px' }}>
                    Claim additional territory to get your first {selection.toLowerCase()}!
                </Typography>
            )}
        </div>
    );
};

export default Territory;
