import React from 'react';
import { Card, CardContent, CardHeader, Grid, Typography } from '@mui/material';

const Allies = () => {
    return (
    <Typography variant="h6" align="center">
        Coming soon...
    </Typography>);
}

export default Allies;
