import axios from "./axiosConfig";
import config from "./config";

const API_URL = config.API_URL + "users";

const getUser = async () => {
	return await axios.get(API_URL + "/user");
};

const getExternalUser = async (id) => {
	return await axios.post(API_URL + "/externalUser", { id: id });
};

const getUserHexagons = async (type, id) => {
	return await axios.post(API_URL + "/userHexagons", { type: type, id: id });
};

const logout = async () => {
	return await axios.get(API_URL + "/logout");
}

const login = async (username, password) => {
	return await axios.post(API_URL + "/login", { username: username, password: password });
};

const register = async (username, password, email) => {
	return await axios.post(API_URL + "/register", { username: username, password: password, email: email });
};

const exportedObject = {
	getUser,
	getExternalUser,
	getUserHexagons,
    login,
    register,
	logout
};

export default exportedObject;
