import React, { useState } from 'react';
import { useNavigate, useOutletContext, Link } from "react-router-dom";

import { Container, TextField, Button, Typography, Snackbar, Alert } from '@mui/material';

import usersService from '../services/users.service';

const Login = () => {
  const [user, setUser] = useOutletContext();

  const [formData, setFormData] = useState({ username: '', password: '' });
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('success');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const loginResult = await usersService.login(formData.username, formData.password);

      // Handle login success
      setUser(loginResult.data.user);

      // Redirect to home page after successful login
      navigate("/map");

    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Login failed';
      setMessage(errorMessage);
      setSeverity('error');
      setOpen(true);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Container component="main" maxWidth="xs">
      <Typography component="h1" variant="h5" sx={{ mt: 6 }}>
        Sign in
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="username"
          label="Username"
          name="username"
          autoFocus
          onChange={handleChange}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="password"
          label="Password"
          type="password"
          name="password"
          onChange={handleChange}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
        >
          Sign In
        </Button>
      </form>
      <Typography variant="body2" sx={{ mt: 2, textAlign: 'center' }}>
        Don't have an account yet?
        <Link to="/register" style={{
          textDecoration: 'none',
          color: '#bf5700',
          marginLeft: '5px'
        }}>
          Register
        </Link>
      </Typography>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default Login;
